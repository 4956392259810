<template lang="pug">
div
  transition(
    enter-active-class='transition-opacity ease-out duration-200'
    enter-from-class='opacity-0'
    leave-active-class='transition-opacity ease-in duration-200'
    leave-to-class='opacity-0'
  )
    //- Set font color to default so the readMore only cares about the button color.
    div(v-show='isActive')
      template(v-for='widget in data.widgets')
        slot(:data='widget')

  button(
    class='block mx-auto font-bold text-lg outline-none focus:outline-none md:hidden'
    :class='actionTextColor'
    @click='toggle'
  )
    span(class='flex items-center')
      | {{ actionText }}
      ArrowIcon(class='transform fill-current w-4 h-4 ml-2' :class='{ "rotate-180": isActive }')
</template>

<script setup lang="ts">
import ArrowIcon from '@/assets/arrow.svg?component'

const { $device, $storyblok } = useNuxtApp()

interface ReadMoreWidget extends Widgets {
  color: string
}

const { data } = defineProps<{
  data: ReadMoreWidget
}>()

const isOpen: Ref<boolean> = ref(false)

// While editing we need to make sure the accordion stays open so we don't hide the content
const isActive = computed(() => {
  return $storyblok.isEditing.value || isOpen.value || !$device.value.isMobile
})

const actionText = computed(() => {
  return isActive.value ? 'Show Less' : 'Show More'
})

const actionTextColor = computed(() => {
  return generateFontColorClass(data.color)
})

function toggle() {
  isOpen.value = !isOpen.value
}
</script>
